.title-container {
    overflow: hidden;
  }

  .text-left {
    float: left;
  }
  
  .text-right {
    float: right;
  } 

  .text-size {
      font-size: 30px;
  }

  .attendanceBtn {
    height: 25px;
    width: 25px;
    margin: 2px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    padding: 0;
  }

  .colorValue {
    color: black;
  }

  .tardy {
    color: #f7b924;
    font-weight: bold;
  }

  .absent {
    color: #f77694;
    font-weight: bold;
  }

  .horizontal {
    min-height: 140px;
  }

  .attendanceBtn2 {
    height: 30px;
    max-width: 90px;
    width: 80%;
    margin: 4px;
    text-align: center;
    font-size: 10px;
    /* font-weight: bold; */
    padding: 0;
    text-align: center;
    align-items: center;
    border: 3px solid white;
  }

  .horizontalBox {
    max-width: 80px;
  }