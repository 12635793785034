.title-container {
    overflow: hidden;
  }

  .text-left {
    float: left;
  }
  
  .text-right {
    float: right;
  } 

  .text-size {
      font-size: 30px;
  }

  .gradeBtn {
    height: 35px;
    width: 50px;
    margin: 2px;
    font-size: 10px;
    font-weight: bold;
  }

