.react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid gray;
    background-color: white;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }


  .react-calendar__tile.styledTile {
    /* background: #794c8a; */
    background: none;
    color: white;
    /* border: 3px solid #eeeeee; */
  }

  .react-calendar__tile.styledTile1 {
      /* background: #794c8a; */
      background: none;
      color: white;
  }

  .react-calendar__tile.styledTile2  {
    /* background: #3f6ad8; */
    background: none;
    color: white;
  }   

  .react-calendar__tile.styledTile3  {
    /* background: #3ac47d; */
    background: none;
    color: white;
  }

  .react-calendar__tile.react-calendar__tile--active.styledTile, 
  .react-calendar__tile.react-calendar__tile--active.styledTile:hover, 
  .react-calendar__tile.react-calendar__tile--active.styledTile:enabled {
      /* border: 4px solid #eeeeee; */
      /* background: #794c8a; */
      background: none;
  }

.react-calendar__tile--active.noBackground {
    background: #ffffa9;
    color: black;
}