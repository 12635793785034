pwa-camera-modal-instance, pwa-action-sheet{
    z-index: 99999;
}

.viewModalImage1 {
    object-fit: contain; 
    /* max-height: 300px; */
    /* border-radius: 8px;  */
    /* border: 2px solid #6c757d; */
    margin: auto;
    margin-left: 0px;
    margin-top: 0px; 
    display: block;
    width: 50%
}

.viewModalImage2 {
    object-fit: contain; 
    /* max-height: 300px; */
    border-radius: 4px; 
    border: 2px solid #6c757d;
    margin: auto;
    margin-left: 0px;
    margin-top: 0px; 
    display: block;
    width: 100%
}