.boxes {
  border: 2px solid white;
  border-radius: 4px;
  font-size: 11px;
}

.boxesGrid {
  /* border: 2px solid white; */
  border-radius: 4px;
  font-size: 11px;
}

.warnings {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 3px;
}
.warnings2 {
  left: 17px;
}
.warnings3 {
  left: 32px;
}

.warningsSeat {
  position: relative;
  top:-5px;
  left: -2px;
  width: 12px;
  height: 12px; 
  border: 2px solid white;
  border-radius: 3px;
  display: inline-block;
}

.genericBadge {
  position: relative;
  top:-9px;
  right: 2px;
  min-width: 10px;
  /* width: 10px; */
  height: 12px; 
  /* border: 2px solid white; */
  border-radius: 3px;
  display: inline;
  font-size: 8px;
  padding: 2px;
}

.attendanceBadge {
  position: relative;
  top: -2px;
  left: 2px;
  width: 12px;
  height: 12px; 
  /* border: 2px solid white; */
  border-radius: 3px;
  display: inline;
}

.warnings2Seat {
  left: 4px;
}

.warnings3Seat {
  left: 10px;
}

.warning-dropdown.dropdown-toggle::after{
  display: none; 
}

/* .dropdown-toggle::after {
  display: none;
} */

.boxLeft {
  border-right: 1px solid white;
}

.boxRight {
  border-left: 1px solid white;
  color: #6c757d;
}
.tooltip-inner {
  /* max-width: 236px !important; */
  /* height: 76px; */
  /* font-size: 12px; */
  /* padding: 10px 15px 10px 20px; */
  background: #FFFFFF;
  /* color: rgba(0, 0, 0, .7); */
  border: 1px solid #737373;
  /* text-align: left; */
  color: black;
}

.badge-dot-info {
  position: absolute;
  border: white solid 2px;
  right:-12px;
  top:-20px;
  padding: 0px;
  width: 4px;
  height: 38px;
  margin: 0px;
  /* float: left; */
  /* margin-bottom: 0.3rem; */
}

.overflow-hid-div-warning{
  overflow:hidden;
  /* margin-left:50px; */
  width:72px;
  height: 38px;
  margin: 0px;
  background-color:grey;
}

.inner-warnings {
  width:100px;
  position: absolute;
  left:10px;
  top:-3px;
}

.textChoice {
  color: black
}

.containerWarnings {
  position:relative;
}

.innerRightWarnings{
  /* width:50px;
  height: 50px; */
  /* background-color:green; */
  position: absolute;
  right:1px;
  top:1px;
  margin: 0px
}


.stripes.black {
  background: repeating-linear-gradient(
    45deg,
    #353b41 0px,
    #353b41 20px,
    #686E74 20px,
    #686E74 40px
  );
}

.stripes.gold {
  background: repeating-linear-gradient(
    45deg,
    #d3ac1e 0px,
    #d3ac1e 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.alt {
  background: repeating-linear-gradient(
    45deg,
    #794c8a 0px,
    #794c8a 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.primary {
  background: repeating-linear-gradient(
    45deg,
    #3f6ad8 0px,
    #3f6ad8 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.success {
  background: repeating-linear-gradient(
    45deg,
    #3ac47d 0px,
    #3ac47d 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.successAttendance {
  background: repeating-linear-gradient(
    45deg,
    #3ac47d 0px,
    #3ac47d 20px,
    #279b5f 20px,
    #279b5f 40px
  );
}

.stripes.warning {
  background: repeating-linear-gradient(
    45deg,
    #f7b924 0px,
    #f7b924 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.warningAttendance {
  background: repeating-linear-gradient(
    45deg,
    #f7b924 0px,
    #f7b924 20px,
    #d49f21 20px,
    #d49f21 40px
  );
}

.stripes.flagVariant {
  background: repeating-linear-gradient(
    45deg,
    #fd7e14 0px,
    #fd7e14 20px,
    #d92550 20px,
    #d92550 40px
  );
}

.stripes.danger {
  background: repeating-linear-gradient(
    45deg,
    #d92550 0px,
    #d92550 20px,
    #FF5883 20px,
    #FF5883 40px
  );
}

.stripes.lightVariant {
  background: repeating-linear-gradient(
    45deg,
    #d92550 0px,
    #d92550 20px,
    #BBBBBB 20px,
    #BBBBBB 40px
  );
}

/* .gridScroll {
  overflow-x: auto;
  max-width: 1000px;
  white-space: nowrap;
} */