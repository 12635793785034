.ingredient-grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 5px;
  column-gap: 40px;
  justify-content: flex-start;
}

.ingredient-grid1 {
  display: grid;
  grid-template-columns: repeat(1, auto);
  row-gap: 5px;
  column-gap: 40px;
  justify-content: flex-start;
}

.ingredient-grid4 {
  display: grid;
  grid-template-columns: repeat(4, auto);
  row-gap: 5px;
  column-gap: 40px;
  justify-content: flex-start;
}


.ingredient-grid3 {
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: 5px;
  column-gap: 40px;
  justify-content: flex-start;
}

.ingredient-grid2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 5px;
  column-gap: 40px;
  justify-content: flex-start;
}

.ingredient-grid3close {
  display: grid;
  grid-template-columns: repeat(3, auto);
  row-gap: 5px;
  column-gap: 10px;
  justify-content: flex-start;
}

.ingredient-gridClass {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 5px;
  column-gap: 10px;
  justify-content: flex-start;
}