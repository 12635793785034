.modalImage {
    object-fit: cover;
    width: auto;
    height: auto;
    max-height: 400px;
    max-width: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: auto;
    vertical-align: center;
}

.addButton {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    height: 200px;
}

.border-alt {
    border-color: #794c8a;
    border-width: 1px;
    border-style: solid;
    color: orange;
}

.viewModalImage {
    object-fit: contain; 
    /* max-height: 300px; */
    border-radius: 4px; 
    border: 2px solid #6c757d;
    margin: auto; 
    display: block;
    width: 100%
}