.wrapperCard {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}

.stripes.pending {
    background: repeating-linear-gradient(
      30deg,
      #3ac47d 0px,
      #3ac47d 20px,
      #279b5f 20px,
      #279b5f 40px

      /* #3ac47d 0px,
      #3ac47d 20px,
      #279b5f 20px,
      #279b5f 40px */
    );
  }

  .border-flag {
    border-color: #fd7e14;
  }

  /* 6c757d - grey */