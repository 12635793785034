.gridBox {
    border: 2px solid #6c757d;
    border-radius: 3px;
}

.mytooltip > .tooltip-inner {
    max-width: none;
    /* width:30% */
    /* width: 7em */
  }
  
  /* .mytooltip > .tooltip-arrow {
    ...
  } */

  /* .tooltip-inner {
    max-width: none; 
} */

.checkoutsGrid {
  position: absolute;
  top:-8px;
  right: -8px;
  width: 16px;
  height: 16px;
  border: 2px solid white;
  border-radius: 3px;
  display: inline-block;
}