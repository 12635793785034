.wordWrapCont {
    word-wrap: break-word;
    width: 100%;
    white-space: normal;
}

.mainRosterButton.dropdown-toggle::after {
    color:white !important; 
}

.my-modal {
    width: 90vw;    /* Occupy the 90% of the screen width */
    max-width: 98vw;
}

.my-modal2 {
  width: 80vw;    /* Occupy the 90% of the screen width */
  max-width: 70vw;
}

.my-modal3 {
  /* width: 90vw; */
  max-width: 85vw;
  /* width: 80vw; */
  /* height: 80vw; */
  max-height: 80vw;
}

.my-modal4 {
  /* width: 90vw; */
  max-width: 700px;
  width: 80vw;
  /* height: 80vw; */
  max-height: 80vw;
}

.my-modal5 {
  /* width: 90vw; */
  max-width: 940px;
  width: 90vw;
  /* height: 80vw; */
  max-height: 80vw;
}

.my-modal6 {
  /* width: 90vw; */
  max-width: 400px;
  width: 400px;
  /* height: 80vw; */
  max-height: 80vw;
}

.my-modal7 {
  /* width: 90vw; */
  max-width: 600px;
  width: 600px;
  /* height: 80vw; */
  max-height: 80vw;
}

.statusTooltip > .tooltip-inner {
  max-width: 100%;
  width: 400px
}

.popover{
  max-width: none;
}

.hide {
  display: none;
}

.attendanceString.success {
  color: black;
  font-weight: bold;
}

.attendanceString.success.secondary {
  color: white;
  font-weight: bold;
}

.attendanceString.warning {
  color: darkorange;
  font-weight: bold;
}

.attendanceString.danger {
  color: red;
  font-weight: bold;
}

.innerRight{
  /* width:50px;
  height: 50px; */
  /* background-color:green; */
  position: absolute;
  left:12px;
  top:22px;
}

.innerLogin{
  /* width:50px;
  height: 50px; */
  /* background-color:green; */
  position: absolute;
  left:12px;
  top:6px;
}

.statusMenuLocation {
  position: absolute;
  bottom: 4em;
  /* right: 0em */
}

.borderLogin {
  border: 2px solid #d92550
}