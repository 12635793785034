.approved {
    opacity: 0.5;

}

button.approved {
  /* opacity: 0.5; */
  background-color: green;
}

p.approved, a.approved {
  /* opacity: 0.5; */
  color: green;
}

a.pending, a.pending {
  /* opacity: 0.5; */
  color: #495057;
}

.rejected {
  opacity: 0.5;
  /* background-color: lightpink; */
}

button.rejected {
  /* opacity: 0.5; */
  background-color: red;
}

p.rejected, a.rejected {
  /* opacity: 0.5; */
  color: red;
}

.container{
    position:relative;
    padding-left: 10px;
}
  .overflow-hid-div{
    overflow:hidden;
    /* margin-left:50px; */
    width:44px;
    height: 14px;
  }
  /* .overflow-hid-div-warning{
    background-color:grey;
  } */
  .inner{
    /* width:50px;
    height: 50px; */
    /* background-color:green; */
    position: absolute;
    left:-5px;
    top:-1px;
  }
  
div[data-warning='hidden'] {
    display: none
}  

div[data-warning='visible'] {
    display: inline-flex
}  

.box {
  /* width: min-content; */
  /* display: table; */
  position: relative;
  /* background: #334b64; */
  /* margin: 0px auto 20px auto; */
  /* box-shadow: 0px 0px 50px rgba(29, 80, 123, 0.3); */
}

.text {
  /* color: white; */
  /* font-size: 10; */
  /* text-align: center; */
  position: relative;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  white-space: pre-wrap
}

.hideCategory {
  display:none
}

.yourTable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

table td, table td * {
  vertical-align: top;
}