.borderCurrent {
    border: 3px solid #ffffff;
}

.borderOther {
    border: 3px solid #6c757d;
}

.borderBottom {
    border: 3px solid #ffffff;
}

.textBottom {
    font-weight: bold;
 }
