.dailytooltip > .tooltip-inner {
    max-width: 100%;
    margin: 5px;
  }
  
.dailyPill {
    margin: 5px
}

.tooltip-arrow {
  display: none !important;
}