.show {
    display: "block";
}

.hide {
    display: "none"
}

.menuLocation {
    position: absolute;
    top: 4em;
    right: 0em
}

.viewed {
    opacity: 30%;
}