.underline {
    text-decoration: 'underline'
}

.boldText {
    font-weight: bold;
    background-color: 'green';
}

.active.hideActive {
    display:none;
}

.edit.hideEdit {
    display:none;
}