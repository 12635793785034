.cardButton {
    margin-right: 10px;
}

.checkedOut {
    border: 2px solid black
}

.arrowBg {
    background-color: transparent;
    border: none
}

.storage {
    display: inline;
}

.outBorder {
    border: 2px solid #fd7e14;
}

.availableBorder {
    border: 2px solid #3ac47d
}

.checkCss {
    /* border: 2px solid #343a40 */
}

.whereBorder {
    border: 2px solid #3f6ad8
}

.expanded {
    background-color: white;
}

.col-md-3.sticky-top {
    align-self: flex-start;
}

.badgeEvents.sticky-top {
    align-self: flex-start;
    /* padding-top: 20px; */
}