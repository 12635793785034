.indentBox {
    margin-left: 25px;
}

.completeStyle, .incompleteButton  {
    opacity: 65%;
}

pwa-camera-modal-instance, pwa-action-sheet{
    z-index: 99999;
}